<template>
  <div id="app">
    <div class="container-fluid">
      <div
        class="row no-gutters align-items-center justify-content-center nea-container"
      >
        <div class="col quader-container">
          <TopBar />
          <div class="main-row row p-0 m-0 no-gutters">
            <div class="col">
              <div class="card quader-content">
                <ImprintLink v-if="!globalState.isMobile" />
                <LanguageNavigation v-if="!globalState.isMobile" />
                <transition name="fade">
                  <router-view />
                </transition>
                <transition name="bounce-down">
                  <MobileMenu v-if="this.showMenu && globalState.isMobile" />
                </transition>
                <transition name="bounce">
                  <div v-if="showImprint" class="imprint-container">
                    <div class="row align-items-start" style="height: 20%">
                      <div class="col text-right">
                        <font-awesome-icon
                          icon="times"
                          size="lg"
                          @click="showImprint = false"
                        />
                      </div>
                    </div>
                    <div class="row align-items-end" style="height: 80%">
                      <div
                        class="col"
                        v-html="this.$t('general.imprinthtml')"
                      ></div>
                    </div>
                  </div>
                  <div v-if="showContact" class="contact-container">
                    <div class="row align-items-end" style="height: 100%">
                      <div class="col h-100">
                        <font-awesome-icon
                          icon="times"
                          size="lg"
                          @click="showContact = false"
                          class="contact-close"
                        />
                        <iframe
                          style="width: 100%; height: 100%; border: none"
                          :src="this.$t('general.contacturl')"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="row mt-2 d-flex">
            <div class="col">
              <SwiperNavigation
                v-if="!globalState.isMobile && showSwipeNav"
                v-bind:maxlength="this.swipeNav.maxlength"
                v-bind:index="this.swipeNav.currentIndex"
              />
            </div>
            <div class="col text-right">
              <ContactButton v-if="!globalState.isMobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <cookie-law :buttonText="'Gelesen und akzeptiert'">
      <div slot="message">
        Diese Website nutzt Cookies um die Benutzererfahrung zu verbessern.<br />
        <a href="/dsgvo/" target="_blank">Bitte lesen Sie unsere DSGVO Info</a>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import TopBar from "./components/TopBar.vue";
import SwiperNavigation from "./components/SwiperNavigation.vue";
import { GlobalState } from "./utils/GlobalState";
import LanguageNavigation from "./components/LanguageNavigation.vue";
import ImprintLink from "./components/ImprintLink.vue";
import ContactButton from "./components/ContactButton.vue";
import MobileMenu from "./components/MobileMenu.vue";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    TopBar,
    RouterView,
    SwiperNavigation,
    LanguageNavigation,
    ImprintLink,
    ContactButton,
    MobileMenu,
    CookieLaw,
  },

  methods: {
    handleSwiperMounted: function (obj) {
      this.showSwipeNav = true;
      this.swipeNav.currentIndex = obj.currentIndex;
      this.swipeNav.maxlength = obj.maxlength;
    },
    handleSwiperChanged: function (obj) {
      this.swipeNav.currentIndex = obj.currentIndex;
    },
    handleSwiperUnmounted: function () {
      this.showSwipeNav = false;
    },
    toggleImprint: function () {
      this.showContact = false;
      this.showMenu = false;
      this.showImprint = !this.showImprint;
    },
    toggleContact: function () {
      this.showImprint = false;
      this.showMenu = false;
      this.showContact = !this.showContact;
    },
    toggleMenu: function () {
      this.showMenu = !this.showMenu;
    },
  },
  watch: {
    $route: function (to, from) {
      this.showMenu = false;
    },
  },
  created() {
    GlobalState.$on("swiper-mounted", this.handleSwiperMounted);
    GlobalState.$on("swiper-changed", this.handleSwiperChanged);
    GlobalState.$on("swiper-unmounted", this.handleSwiperUnmounted);
    GlobalState.$on("toggle-contact", this.toggleContact);
    GlobalState.$on("toggle-imprint", this.toggleImprint);
    GlobalState.$on("toggle-menu", this.toggleMenu);
  },
  beforeDestroy() {
    GlobalState.$off("swiper-mounted", this.handleSwiperMounted);
    GlobalState.$off("swiper-changed", this.handleSwiperChanged);
    GlobalState.$off("swiper-unmounted", this.handleSwiperUnmounted);
    GlobalState.$off("toggle-imprint", this.toggleImprint);
    GlobalState.$off("toggle-contact", this.toggleContact);
    GlobalState.$off("toggle-menu", this.toggleMenu);
  },
  data() {
    return {
      globalState: GlobalState,
      showImprint: false,
      showContact: false,
      showSwipeNav: false,
      showMenu: false,
      swipeNav: {
        currentIndex: 0,
        maxlength: 0,
      },
    };
  },
};
</script>

<style>
@font-face {
  font-family: "avenir";
  src: url("./assets/fonts/Avenir-Book.ttf");
}
@font-face {
  font-family: "avenir-black";
  src: url("./assets/fonts/Avenir-Black.ttf");
}

@font-face {
  font-family: "avenir-medium";
  src: url("./assets/fonts/Avenir-Medium.ttf");
}

.fa-lg:hover {
  cursor: pointer;
  color: #9c9c9c;
}

html,
body {
  font-family: "avenir" !important;
  background-color: rgb(217, 218, 222) !important;
  height: 100%;
}

.nea-link {
  color: inherit;
  font: inherit;
  text-decoration: none;
  appearance: none;
}

.nea-link:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}

.bold {
  font-family: "avenir-black";
}

p {
  margin-bottom: 0 !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.control-container {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 85%;
}

.control {
  height: 100%;
  z-index: 10000;
}

.nea-container {
  min-height: 100vh;
}

.col.quader-container {
  max-width: min(80vmin, 800px);
}

.quader-content {
  height: min(80vmin, 800px);
}

.quader-content > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.bounce-down-enter-active {
  animation: bounce-down 0.4s;
}
.bounce-down-leave-active {
  animation: bounce-down 0.3s reverse;
}
@keyframes bounce-down {
  0% {
    bottom: 400px;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.4s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    top: 400px;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.fade-enter-active {
  animation: fade-in 0.4s;
}
.fade-leave-active {
  animation: fade-in 0.4s reverse;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imprint-container,
.contact-container {
  overflow: hidden;
  background-color: rgba(200, 200, 200, 0.975);
  padding: 30px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.contact-container {
  padding: 0;
}

.contact-container .contact-close {
  position: absolute;
  top: 30px;
  right: 45px;
  margin: 10px;
}

/* mobile phone */
@media (max-width: 767px) {
  .container-fluid {
    padding: 0 !important;
    margin: 0 !important;
  }
  body {
    padding: 0 !important;
  }

  #app,
  .container-fluid,
  .nea-container,
  .quader-container,
  .main-row {
    height: 100%;
  }

  .quader-content {
    height: 100%;
    height: 100svh;
  }

  .menu-container {
    z-index: 199;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.65);
    padding: 2px 7px;
  }

  .col.quader-container {
    width: 100vw;
    max-width: none;
  }

  .imprint-container {
    padding: 10px;
    top: 36px !important;
  }

  .contact-container {
    padding: 0px;
    top: 36px !important;
  }

  .contact-container .contact-close {
    position: absolute;
    top: 10px;
    right: 25px;
  }

  html,
  body {
    overflow: hidden !important;
  }
}
</style>
