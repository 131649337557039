<template>
  <div class="workentry-container">
    <div class="workentry-background">
      <video
        class="bg"
        v-if="entry.imageurl.match(/(mp4|m4v)/)"
        :src="entry.imageurl"
        muted
        autoplay
        playsinline=""
      ></video>
      <img
        class="bg"
        v-if="entry.imageurl.match(/(jpg|jpeg|png)/)"
        :src="entry.imageurl"
        alt=""
      />
      <div class="workentry-title-container row align-items-end">
        <div class="col" v-if="!hideText && !show">
          <h2 :style="{ color: entry.headcolor }">{{ entry.title }}</h2>
          <h4 :style="{ color: entry.textcolor }" v-html="entry.subtitle"></h4>
          <div class="title-link" :style="{ color: entry.headcolor }">
            <localized-link
              :to="entry.linkurl"
              v-if="entry.linkurl"
              class="nea-link"
            >
              {{ entry.linkname }}
            </localized-link>
            <button v-if="!entry.linkurl" class="nea-link" @click="show = true">
              {{ entry.linkname }}
            </button>
            <span v-if="hasLightBox">&nbsp;|&nbsp;</span>
            <button
              v-if="hasLightBox"
              class="nea-link"
              @click="$emit('lightbox-open', true)"
            >
              {{ this.$t("general.gallery") }}
            </button>
            <span v-if="hasProductLink">&nbsp;|&nbsp;</span>
            <a
              v-if="hasProductLink"
              class="nea-link"
              :href="entry.produktlink + ''"
              target="_blank"
              >{{ this.$t("general.website") }}</a
            >
          </div>
        </div>
      </div>

      <transition name="bounce">
        <div
          v-if="show"
          class="description-container"
          :style="{ 'background-color': convertHex(entry.bgcolor, 55) }"
        >
          <div class="row align-items-start top-space" style="height: 20%">
            <div class="col text-right">
              <font-awesome-icon
                style="z-index: 10001"
                icon="times"
                size="lg"
                @click="show = false"
              />
            </div>
          </div>
          <div class="row align-items-end description">
            <div class="col">
              <div class="row">
                <div class="col" v-html="entry.description"></div>
              </div>
              <div class="row mt-3">
                <div class="col"></div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: "MyWorkEntry",
  data: function () {
    return {
      show: false,
      hideText: false,
    };
  },
  props: {
    entry: Object,
  },
  computed: {
    hasProductLink() {
      return this.entry.produktlink && this.entry.produktlink != '';
    },
    hasLightBox() {
      return this.entry.images.length > 0;
    }
  },
  methods: {
    convertHex: function (color, opacity) {
      color = color.replace("#", "");
      let r = parseInt(color.substring(0, 2), 16);
      let g = parseInt(color.substring(2, 4), 16);
      let b = parseInt(color.substring(4, 6), 16);
      let result = `rgba(${r},${g},${b},${opacity / 100})`;
      return result;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.workentry-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .workentry-background {
    width: 100%;
    height: 100%;
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% center; */
    position: relative;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 70% center;
      pointer-events: none;
    }
  }

  .workentry-title-container {
    height: 100%;
    padding: 50px;
    padding-bottom: 40px;
    padding-right: 30%;
    color: #efefef;

    .title-link {
      font-family: "avenir" !important;
      font-size: clamp(1.25rem, 3vmin, 31px);
    }

    h2 {
      font-size: clamp(46px, 5vmin, 72px);
      line-height: 1;
      font-family: "avenir-medium";
      margin-bottom: 0.4em;
    }

    h4 {
      margin-bottom: 0 !important;
      font-size: clamp(1.25rem, 3vmin, 31px);
      line-height: 1.35;
      margin-bottom: 0;
    }
  }

  .description-container {
    overflow: hidden;
    background-color: rgba(200, 200, 200, 0.8);
    padding: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;

    font-size: clamp(1.2rem, 2vmin, 1.625rem);
    margin-bottom: 0;
    line-height: 1.5;

    .description {
      padding-right: min(10vw, 100px);
      overflow: auto;
      height: 80%;
    }
  }

  button {
    appearance: none;
    background: transparent;
    border: none;
    font: inherit;
    padding: 0;
  }
  .top-space {
    height: 20%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .workentry-title-container {
      padding: 20px;
    }

    .description-container {
      padding: 10px;
      padding-top: 40px;
    }

    .description {
      padding: 10px;
    }
  }
}
</style>
