import Vue from 'vue'
export const GlobalState = new Vue({
  data() {
    return {
      mediaQuery: null,
      isMobile: false,
    }
  },
  methods: {
    handleMediaQueryChange: function (query) {
      this.isMobile = query.matches;
    },
  },
  created() {
    this.mediaQuery = window.matchMedia("(max-width: 767px)");
    this.isMobile = this.mediaQuery.matches;
    this.mediaQuery.addEventListener("change", this.handleMediaQueryChange);
  },
  beforeDestroy() {
    this.mediaQuery.removeEventListener("change", this.handleMediaQueryChange);
  }
})