import Vue from "vue";
import LangRouter from "vue-lang-router";
import translations from "../lang/translations";
import localizedURLs from "../lang/localized-urls";

import Home from "../views/Home.vue";
import Services from "../views/Services.vue";
import Projects from "../views/Projects.vue";

Vue.use(LangRouter, {
  defaultLanguage: "en",
  translations,
  localizedURLs
});

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/services/:index?",
    name: "Services",
    component: Services
  },
  {
    path: "/projects/:index?",
    name: "Projects",
    component: Projects
  }
];

// Initiate router
const router = new LangRouter({
  routes,
});

export default router;
