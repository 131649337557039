export default {
  de: {
    name: "DE",
    load: () => {
      return fetch('/lang/translations/de.json').then(response => response.json())
    }
  },
  en: {
    name: "EN",
    load: () => {
      return fetch('/lang/translations/en.json').then(response => response.json())
    }
  },
  it: {
    name: "IT",
    load: () => {
      return fetch('/lang/translations/it.json').then(response => response.json())
    }
  }
};
