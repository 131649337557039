<template>
  <Swiper v-bind:items="items" />
</template>

<script>
import Swiper from "../components/Swiper.vue";
export default {
  name: "Projects",
  components: {
    Swiper,
  },
  computed: {
    items() {
      return this.$t(`projects`);
    },
  },
};
</script>
