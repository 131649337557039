<template>
  <nav>
    <button class="nea-link" @click="toggleImprint">
      {{ this.$t("general.imprintfield") }}
    </button>
  </nav>
</template>

<script>
import { GlobalState } from "../utils/GlobalState";

export default {
  name: "ImprintLink",
  methods: {
    toggleImprint() {
      GlobalState.$emit("toggle-imprint");
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  color: white;
  position: absolute;
  left: 100%;
  bottom: 100%;
  transform: rotate(90deg);
  transform-origin: bottom left;
  margin-left: 0.5em;

  @media (max-width: 767px) {
    position: static;
    transform: none;
    margin: 0;
    color: black;
  }

  button {
    appearance: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background: transparent;
    border: none;
  }
}
</style>
