import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "vue-lang-router";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-swipe/dist/vue-swipe.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleRight, faAngleLeft, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faAngleRight, faAngleLeft, faTimes, faBars);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
