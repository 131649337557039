<template>
  <MyWorkEntry v-bind:entry="entry" />
</template>

<script>
import MyWorkEntry from "../components/MyWorkEntry.vue";

export default {
  name: "Home",
  computed: {
    entry() {
      return this.$t("home");
    },
  },
  components: {
    MyWorkEntry,
  },
};
</script>

<style scoped>
.fa-times:hover {
  cursor: pointer;
  color: #9c9c9c;
}

.workentry-container {
  width: 100%;
  height: 100%;
}

.workentry-background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% center;
  position: relative;
}

.workentry-title-container {
  height: 100%;
  padding: 50px;
  padding-bottom: 40px;
  padding-right: 200px;
  color: #efefef;
}

.description-container {
  overflow: hidden;
  background-color: rgba(200, 200, 200, 0.8);
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

@font-face {
  font-family: "avenir-medium";
  src: url("./../assets/fonts/Avenir-Medium.ttf");
}

@font-face {
  font-family: "avenir";
  src: url("./../assets/fonts/Avenir-Book.ttf");
}

.workentry-title-container .title-link {
  font-family: "avenir" !important;
  font-size: 23pt;
  line-height: 29pt;
}

.workentry-title-container h2 {
  font-size: 72px;
  line-height: 72px;
  font-family: "avenir-medium";
  margin-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .workentry-title-container h2 {
    font-size: 50px;
    line-height: 50px;
  }

  .workentry-title-container .title-link {
    font-size: 1.25rem;
    line-height: 1.7rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .workentry-title-container {
    padding: 20px;
  }

  .workentry-title-container h2 {
    font-size: 46px;
    line-height: 46px;
  }

  .description-container {
    padding: 10px;
    padding-top: 40px;
  }

  .description {
    padding: 10px;
  }

  .workentry-title-container .title-link {
    font-size: 1.35rem;
  }
}
</style>
