<template>
  <div class="row mb-2 d-flex align-items-end topbar-wrapper">
    <div class="col topbar">
      <Logo />
      <span
        v-if="entry && entry.topbartitle != ''"
        :style="{ color: entry.topbarcolor }"
        class="bold"
        >{{ entry.topbartitle }}</span
      >
    </div>
    <div class="col-1 pl-0 col-md-4 text-right">
      <PageNavigation v-if="!globalState.isMobile" />
      <font-awesome-icon
        v-if="globalState.isMobile"
        icon="bars"
        size="lg"
        @click="toggleMenu"
      />
    </div>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import PageNavigation from "./PageNavigation.vue";
import { GlobalState } from "../utils/GlobalState";

export default {
  name: "TopBar",
  data() {
    return {
      entry: null,
      globalState: GlobalState,
    };
  },
  methods: {
    handleSwiperChanged: function (obj) {
      if (obj && obj.item) {
        this.entry = obj.item;
      } else {
        this.entry = null;
      }
    },
    toggleMenu: function () {
      GlobalState.$emit("toggle-menu");
    },
  },
  created() {
    GlobalState.$on("swiper-changed", this.handleSwiperChanged);
    GlobalState.$on("swiper-mounted", this.handleSwiperChanged);
    GlobalState.$on("swiper-unmounted", this.handleSwiperChanged);
  },
  beforeDestroy() {
    GlobalState.$off("swiper-changed", this.handleSwiperChanged);
    GlobalState.$off("swiper-mounted", this.handleSwiperChanged);
    GlobalState.$off("swiper-unmounted", this.handleSwiperChanged);
  },
  components: {
    Logo,
    PageNavigation,
  },
};
</script>

<style lang="scss" scoped>
.topbar-wrapper {
  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
    width: 100%;
    margin: 0;
    background: rgba(255, 255, 255, 0.65);
    padding: 7px 0 3px;
  }
  .topbar {
    font-size: 16pt;
    @media (max-width: 767px) {
      font-size: 13pt;
    }
  }
}
</style>
