<template>
  <nav class="mobile-menu">
    <PageNavigation />

    <ContactButton :link="true" />
    <ImprintLink />

    <LanguageNavigation />
  </nav>
</template>

<script>
import ImprintLink from "./ImprintLink.vue";
import LanguageNavigation from "./LanguageNavigation.vue";
import PageNavigation from "./PageNavigation.vue";
import ContactButton from "./ContactButton.vue";

export default {
  name: "MobileMenu",
  components: {
    PageNavigation,
    ImprintLink,
    LanguageNavigation,
    ContactButton,
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 2em 38px;
  background-color: white;
  align-items: flex-start;
  font-size: 2em;

  > * {
    position: static;
    transform: none;
    text-align: left;
    width: 100%;
    max-width: 300px;
    color: black;
    margin-left: 0;
  }
}
</style>
