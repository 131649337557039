<template>
  <div class="wrapper">
    <Swipe
      class="my-swipe"
      ref="myswipe"
      :showIndicators="false"
      :prevent="false"
      :auto="0"
      :speed="200"
      :continuous="false"
      v-on:change="swiperChanged($event)"
    >
      <SwipeItem
        v-for="(item, index) in items"
        :key="index"
        :class="'slide' + index"
      >
        <MyWorkEntry
          v-bind:entry="item"
          v-on:lightbox-open="lightboxOpened($event)"
        />
      </SwipeItem>
    </Swipe>
    <lightbox
      v-if="items[currentIndex] !== undefined"
      id="mylightbox"
      ref="lightbox"
      :images="items[currentIndex].images"
    ></lightbox>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vue-swipe";
import Lightbox from "vue-my-photos";
import MyWorkEntry from "./MyWorkEntry.vue";
import { GlobalState } from "../utils/GlobalState";
export default {
  name: "MainContainer",
  props: {
    items: Array,
  },
  data() {
    return {
      currentIndex: 0,
      isDetailOpen: false,
    };
  },
  methods: {
    handleNavChanged: function (idx) {
      this.$refs.myswipe.goto(idx);
    },
    swiperChanged(idx) {
      this.currentIndex = idx;

      let currentRoute = this.$router.currentRoute;
      let newPath = currentRoute.fullPath.slice(0, -1) + this.currentIndex;

      if (currentRoute.fullPath != newPath) {
        this.$router.push({
          path: newPath,
        });
      }

      GlobalState.$emit("swiper-changed", {
        currentIndex: this.currentIndex,
        item: this.items[this.currentIndex],
      });
    },
    lightboxOpened(ev) {
      this.$refs.lightbox.show(this.items[this.currentIndex].images[0]);
    },
    detailsOpened(event) {
      this.isDetailOpen = event;
    },
  },
  mounted() {
    let currentRoute = this.$router.currentRoute;
    let pathIndex = parseInt(currentRoute.params.index);

    if (isNaN(pathIndex)) {
      this.$router.replace({
        path: currentRoute.fullPath + "/" + this.currentIndex,
      });
    } else {
      this.currentIndex = pathIndex;
      this.$refs.myswipe.goto(this.currentIndex);
    }

    GlobalState.$emit("swiper-mounted", {
      currentIndex: this.currentIndex,
      maxlength: this.items.length,
      item: this.items[this.currentIndex],
    });
    GlobalState.$on("nav-changed", this.handleNavChanged);
  },
  watch: {
    $route: function (to, from) {
      if (to.params.index !== undefined) {
        this.currentIndex = parseInt(to.params.index);
        this.$refs.myswipe.goto(this.currentIndex);
      }
    },
  },
  beforeDestroy() {
    GlobalState.$off("nav-changed", this.handleNavChanged);
  },
  destroyed() {
    GlobalState.$emit("swiper-unmounted");
  },
  components: {
    Swipe,
    SwipeItem,
    Lightbox,
    MyWorkEntry,
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}
</style>
