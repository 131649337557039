<template>
  <button type="button" :class="className" @click="toggleContact">
    {{ this.$t("general.contactbutton") }}
  </button>
</template>

<script>
import { GlobalState } from "../utils/GlobalState";
export default {
  name: "ContactButton",
  props: {
    link: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className() {
      return this.link ? "nea-link" : "btn btn-outline-dark";
    },
  },
  methods: {
    toggleContact() {
      GlobalState.$emit("toggle-contact");
    },
  },
};
</script>

<style scoped>
button.nea-link {
  font: inherit;
  background: transparent;
  border: none;
  padding: 0;
}
.btn-outline-dark {
  font-family: "avenir";
  color: black;
}

.btn-outline-dark {
  border-radius: 0;
}
.btn-outline-dark:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
}
</style>
