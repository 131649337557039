<template>
  <div>
    <!-- <button class="btn btn-primary" v-bind:disabled="prevIndex == -1" @click="$emit('index-changed', prevIndex)">prev</button> -->
    <font-awesome-icon icon="angle-left" @click="indexChanged(prevIndex)" />
    <span class="noselect">{{ currentIndex + 1 }} / {{ maxlength }}</span>
    <!-- <button class="btn btn-primary" v-bind:disabled="nextIndex == maxlength" >next</button> -->
    <font-awesome-icon icon="angle-right" @click="indexChanged(nextIndex)" />
  </div>
</template>

<script>
import { GlobalState } from "../utils/GlobalState";

export default {
  name: "SwiperNavigation",
  props: {
    maxlength: Number,
    index: Number,
  },
  methods: {
    indexChanged(idx) {
      if (idx >= 0 && idx < this.maxlength) {
        GlobalState.$emit("nav-changed", idx);
      }
    },
  },
  computed: {
    prevIndex: function () {
      return this.index - 1;
    },
    nextIndex: function () {
      return this.index + 1;
    },
    currentIndex: function () {
      return this.index;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa-angle-right {
  margin-left: 8px;
  cursor: pointer;
}
.fa-angle-left {
  margin-right: 8px;
  cursor: pointer;
}
</style>
