import { render, staticRenderFns } from "./PageNavigation.vue?vue&type=template&id=f3cd5e98&scoped=true&"
var script = {}
import style0 from "./PageNavigation.vue?vue&type=style&index=0&id=f3cd5e98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3cd5e98",
  null
  
)

export default component.exports