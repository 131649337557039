<template>
  <nav>
    <language-switcher v-slot="{ links }" class="switcher">
      <router-link
        class="nea-link"
        v-for="link in links"
        :key="link.langIndex"
        :to="link.url"
      >
        <span>{{ link.langName }}</span>
      </router-link>
    </language-switcher>
  </nav>
</template>

<script>
import TopBar from "./TopBar.vue";

export default {
  name: "Navigation",
  components: {
    TopBar,
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: absolute;
  left: 100%;
  bottom: 0%;
  margin-left: 0.5em;
  .switcher {
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 1em;
  }
}
</style>
